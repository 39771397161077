import './App.css';
import React, { useState, lazy, Suspense } from 'react';
import { Preloader } from './Components/Preloader';
import 'bootstrap/dist/css/bootstrap.min.css';
import Toast from 'react-bootstrap/Toast'
import { ToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Login } from './Components/Login';
import { Register } from './Components/Register';
import { PanelLogin } from './Components/PanelLogin';
import { Home } from './Components/Home';
import { Dashboard } from './Components/Dashboard';
import { isBrowser } from 'react-device-detect';
import ExeRegister from './Components/ExeRegister';
import { Website } from './Components/Website';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { NotFound } from './Components/NotFound';
import { TermCondition } from './Website/TermCondition';
import { ExhiTermCondition } from './Website/ExhiTermCondition';
import { Privacy } from './Website/Privacy';
import { Cookies } from './Website/Cookies';


function App() {
  // define all menu in lazyload


  const Hold = lazy(
    () => import('./Components/Hold').then(module => ({ default: module.Hold }))
  );
  const About = lazy(
    () => import('./Website/About').then(module => ({ default: module.About }))
  );
  
 
 
  const BookStall = lazy(
    () => import('./Website/BookStall').then(module => ({ default: module.BookStall }))
  );
  const VirtualAccess = lazy(
    () => import('./Website/VirtualAccess').then(module => ({ default: module.VirtualAccess }))
  );
  const Advertising = lazy(
    () => import('./Website/Advertising').then(module => ({ default: module.Advertising }))
  );
  const WhyUs = lazy(
    () => import('./Website/WhyUs').then(module => ({ default: module.WhyUs }))
  );
  const Contact = lazy(
    () => import('./Website/Contact').then(module => ({ default: module.Contact }))
  );
  // const Login = lazy(
  //   () => import('./Components/Login').then(module => ({ default: module.Login }))
  // );
  const Footer = lazy(
    () => import('./Components/Footer').then(module => ({ default: module.Footer }))
  );
  const AuditoriumHall = lazy(
    () => import('./Components/AuditoriumHall').then(module => ({ default: module.AuditoriumHall }))
  );
  const Auditorium = lazy(
    () => import('./Components/Auditorium').then(module => ({ default: module.Auditorium }))
  );
  const HelpDesk = lazy(
    () => import('./Components/HelpDesk').then(module => ({ default: module.HelpDesk }))
  );
  const Exhibition = lazy(
    () => import('./Components/Exhibition').then(module => ({ default: module.Exhibition }))
  );
  const ExhibitionDom = lazy(
    () => import('./Components/ExhibitionDom').then(module => ({ default: module.ExhibitionDom }))
  );
  const SearchStalls = lazy(
    () => import('./Components/SearchStalls').then(module => ({ default: module.SearchStalls }))
  );
  const Stall = lazy(
    () => import('./Components/Stall').then(module => ({ default: module.Stall }))
  );
  const Header = lazy(
    () => import('./Components/Header').then(module => ({ default: module.Header }))
  );
  const OrgDetailView = lazy(
    () => import('./Components/OrgDetailView').then(module => ({ default: module.OrgDetailView }))
  );
  const VisitorLog = lazy(
    () => import('./Components/VisitorLog').then(module => ({ default: module.VisitorLog }))
  );
  const AuditoriumMaster = lazy(
    () => import('./Components/AuditoriumMaster').then(module => ({ default: module.AuditoriumMaster }))
  );
  const Profile = lazy(
    () => import('./Layouts/Profile').then(module => ({ default: module.Profile }))
  );
  const Briefcase = lazy(
    () => import('./Components/Briefcase').then(module => ({ default: module.Briefcase }))
  );
  const StallMangement = lazy(
    () => import('./Components/StallMangement').then(module => ({ default: module.StallMangement }))
  );
  const Notification = lazy(
    () => import('./Components/Notification').then(module => ({ default: module.Notification }))
  );
  const ResetPassword = lazy(
    () => import('./Components/ResetPassword').then(module => ({ default: module.ResetPassword }))
  );
  const Payment = lazy(
    () => import('./Components/Payment').then(module => ({ default: module.Payment }))
  );
  const PaymentPage = lazy(
    () => import('./Components/PaymentPage').then(module => ({ default: module.PaymentPage }))
  );
  const VideoChat = lazy(
    () => import('./WebRtc/VideoChat').then(module => ({ default: module.VideoChat }))
  );
  // const AgentHome = lazy(
  //   () => import('./Components/AgentHome').then(module => ({ default: module.AgentHome }))
  // );

  //end here

  //localStorage.clear();
  //get user details
  const user = useSelector(state => state.userDetails);

  // const user = JSON.parse(users);

  // common data for app

  const minHeight = {
    minHeight: isBrowser ? window.innerHeight : window.innerHeight,
    // height: isBrowser ? window.innerHeight - 120 : window.innerHeight - 30,
    // overflowY: "scroll"


  }

  let commonData = {
    //  "siteurl":  "http://hofficelocal",
    //  "sitename":  "hofficelocal",         
    //  "inquirylink":  "http://hofficelocal", 
    // "siteurl":  "https://bharatexpo.ondemandcrm.co", 
    // "sitename":  "bharatexpo.ondemandcrm.co",          
    // "inquirylink":  "https://hoffice1.ondemandcrm.co",
    "siteurl":  "https://virtualbiz365.ondemandcrm.co",
    "sitename":  "virtualbiz365.ondemandcrm.co",
   "inquirylink":  "https://virtualbiz365.ondemandcrm.co", 
    "AuthorizeKey": "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
    "Agent": "0",
    "Visitor": "1",
    "Organiser": "2",
    "Both": "3",
    "Upload": "3",
    "reduceheight": "100",
    "Embedded": "4",
    "External": "1",
    "Openimg": "2",
    "Openstall": "3",
    "Agent_name": "Sponser",
    "Visitor_name": "Visitor",
    "Organiser_name": "Organizer",
    "Participant_name": "Exhibitor",
    "AUDITORIUM_SPEAKER": "S",
    "AUDITORIUM_SPEAKER_NAME": "Speaker",
    "AUDITORIUM_PARTICIPANT": "P",
    "AUDITORIUM_PARTICIPANT_NAME": "Participant",
    "SCREEN_3D": "1",
    "SCREEN_VIRTUAL": "2",
    "SCREEN_3D_NAME": "3D Screen",
    "SCREEN_VIRTUAL_NAME": "Virtual Screen",
    "LIVE": "1",
    "RECORDED": "2",
    "LIVE_NAME": "Live",
    "RECORDED_NAME": "Recorded",
    "PAIDUSER": "1",
    "FREEUSER": "2",
    "PAIDUSER_NAME": "Paid user",
    "FREEUSER_NAME": "Everyone",
    "TODAYS": "1",
    "PREV_NEXT": "2",
    "CONTACT": "1",
    "RAWCONTACT": "6",
    "LOGIN_BOTH": "B",
    "LOGIN_CRDENTIAL": "C",
    "LOGIN_OTP": "O",
    "LOGIN_DIRECT": "D",
    "PUBLIC_PRIVACY": "1",
    "PRIVATE_PRIVACY": "2",
    "SMALL_STALL_BANNER_SIZE": "575 x 1275",
    "SMALL_STALL_BANNER_WIDTH_SIZE": "575",
    "SMALL_STALL_BANNER_HEIGHT_SIZE": "1275",
    "BIG_STALL_BANNER_SIZE": "750 x 700",
    "BIG_STALL_BANNER_WIDTH_SIZE": "750",
    "BIG_STALL_BANNER_HEIGHT_SIZE": "700",
    "BROADCAST_TIME_MS": "300000", // 5 minute
    "NOTIFICATION_TIME_MS": "30000", // 1/2 minutes
    "NOTIFY_VIDEOLINK": 1,
    "NOTIFY_AUDIOLINK": 2,
    "NOTIFY_INTERESTED": 3,
    "NOTIFY_INQUIRY": 4,
    "NOTIFY_TASK": 5,
    "NOTIFY_GROUPVIDEOLINK": 6,
    "NOTIFY_VIDEOCALL": 6,
    "NOTIFY_INQUIRY_REF": 7,
    "NOTIFY_FEEDBACK": 7,
    "NOTIFY_NEWVISITOR": 8,
    "NOTIFY_APPOINTMENT": 9,
    "APPOINTMENT_PENDING": 1,
    "APPOINTMENT_ACCEPT": 2,
    "APPOINTMENT_RESCHEDULE": 3,
    "APPOINTMENT_CANCEL": 4,
    "SINGLE_CHAT": 20000,
    "CHAT_UNREADCOUNT": "30000", // 1/2 minute
    "minHeight": minHeight, // 
    "ChatPagination": 20,
    "localReference": "http://" + window.location.hostname + ":3000/register/reference/",
    "liveReference": "https://" + window.location.hostname + "/register/reference/",
  }
  //end here

  // toast state and funtion
  const [show, setShow] = useState(false);
  const [toaststatus, setToastStaus] = useState("check status");
  const [toastmsgs, setToastMsgs] = useState("check msg get chang here");
  const [settoastbg, setToastbg] = useState("primary");
  const toastmsg = (status, message, bg) => {
    setToastMsgs(message);
    setToastStaus(status);
    bg ? setToastbg(bg) : setToastbg("primary")
    setShow(true);
  }
  //end here

  return (
    <div className="App">
      {/* toast code start here */}
      <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShow(false)} bg={settoastbg} show={show} delay={3000} autohide >
          {/* <Toast.Header>
            <strong className="me-auto">{toaststatus}</strong>
          </Toast.Header> */}
          {/* <Toast.Body>{toastmsgs}</Toast.Body> */}
          <Toast.Body>
            <div className="col-12 row centerBox ">
              <div className="col-2">
              {toaststatus == "SUCCESS" || toaststatus == "Success"
              ? <img src='/images/global/icon/Sucess.png' className='mx-2' width="30px" /> 
              :
              [toaststatus == "ERROR" || toaststatus == "Error"
                ? <img src='/images/global/icon/Error.png' className='mx-2' width="30px" />
                :
                [toaststatus == "INFO"
                  ? <img src='/images/global/icon/Info.png' className='mx-2' width="30px" />
                  :
                  <img src='/images/global/icon/Warning.png' className='mx-2' width="30px" />
                ]
              ]
            }
              </div>
              <div className="col-10"> <span className={`text-uppercase statusTxt${toaststatus}`}>{toaststatus}</span>  <br/>
              {toastmsgs}
              </div>
            </div>
            

            </Toast.Body>
        </Toast>

      </ToastContainer>

      {/* Routing code stats here */}
      {/* {user.isLoggedIn !== "N" ? <Header /> : ""} */}

      <Router>
        <Suspense fallback={<Preloader />}>
          {!window.location.pathname.includes("hofficeuser")
            ? <Header commonData={commonData} />
            : ""
          }

          <Switch>
            <Route exact path="/" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ?
                    <Website  commonData={commonData} />
                     :
                    [user.user_type != 1 &&  user.exhibitorpass != "2" 
                      ? <Redirect to="/dashboard" />
                      : <Redirect to="/home" />
                    ]

                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/about" render={() => {
              return (
                <>
                  <About />
                </>
              )
            }}>
            </Route>
           
            
            <Route exact path="/bookstall" render={() => {
              return (
                <>
                  <BookStall />
                </>
              )
            }}>
            </Route>
            <Route exact path="/virtual-access" render={() => {
              return (
                <>
                  <VirtualAccess />
                </>
              )
            }}>
            </Route>
            <Route exact path="/advertising" render={() => {
              return (
                <>
                  <Advertising />
                </>
              )
            }}>
            </Route>
            <Route exact path="/whyus" render={() => {
              return (
                <>
                  <WhyUs />
                </>
              )
            }}>
            </Route>
            <Route exact path="/contact" render={() => {
              return (
                <>
                  <Contact commonData={commonData} toast={toastmsg} />
                </>
              )
            }}>
            </Route>
            <Route exact path="/terms-policy" render={() => {
              return (
                <>
                  <TermCondition />
                </>
              )
            }}>
            </Route>
            <Route exact path="/exhibitor-terms-policy" render={() => {
              return (
                <>
                  <ExhiTermCondition />
                </>
              )
            }}>
            </Route>
            <Route exact path="/privacy-policy" render={() => {
              return (
                <>
                  <Privacy />
                </>
              )
            }}>
            </Route>
            <Route exact path="/cookie-policy" render={() => {
              return (
                <>
                  <Cookies />
                </>
              )
            }}>
            </Route>
            <Route exact path="/login" render={() => {
              return (
                <>
                  {user.isLoggedIn !== "N" ? <Redirect to="/home" /> :
                    <Login commonData={commonData} toast={toastmsg} />}

                </>
              )
            }}>
            </Route>
            <Route exact path="/register/" render={() => {
              return (
                <>
                  {user.isLoggedIn !== "N" ? <Redirect to="/home" /> :
                    <Register commonData={commonData} toast={toastmsg} />}

                </>
              )
            }}>
            </Route>
            <Route exact path="/register/reference/:name" render={() => {
              return (
                <>
                  {user.isLoggedIn !== "N" ? <Redirect to="/home" /> :
                    <Register commonData={commonData} toast={toastmsg} />}

                </>
              )
            }}>
            </Route>
            <Route exact path="/exhibitor-register/:name" render={() => {
              return (
                <>
                  {user.isLoggedIn !== "N" ? <Redirect to="/home" /> :
                    <ExeRegister commonData={commonData} toast={toastmsg} />}
                </>
              )
            }}>
            </Route>
            <Route exact path="/notification" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <Notification commonData={commonData} />
                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/home" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <Home commonData={commonData} />
                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/auditoriumhall" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <AuditoriumHall commonData={commonData} />
                  }

                </>
              )
            }}></Route>
            <Route exact path="/auditorium/:id" render={() => {
              return (
                <>
                {user.isLoggedIn === "N" ? localStorage.setItem("linklasturl", window.location.pathname) : localStorage.setItem("linklasturl", "")}
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <Auditorium commonData={commonData} />
                  }

                </>
              )
            }}></Route>
            <Route exact path="/helpdesk" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <HelpDesk commonData={commonData} />
                  }

                </>
              )
            }}></Route>
            <Route exact path="/exhibition" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <Exhibition commonData={commonData} />
                  }

                </>
              )
            }}></Route>
            <Route exact path="/exhibition/:name" render={() => {
              return (
                <>
                {user.isLoggedIn === "N" ? localStorage.setItem("linklasturl", window.location.pathname) : localStorage.setItem("linklasturl", "")}
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <ExhibitionDom commonData={commonData} />
                  }

                </>
              )
            }}></Route>
            <Route exact path="/search_stalls" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <SearchStalls commonData={commonData} />
                  }

                </>
              )
            }}></Route>
            <Route exact path="/stall/:name" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? localStorage.setItem("linklasturl", window.location.pathname) : localStorage.setItem("linklasturl", "")}
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <Stall commonData={commonData} />
                  }

                </>
              )
            }}></Route>
            <Route exact path="/hold" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Hold /> :
                    <Hold commonData={commonData} />}

                </>
              )
            }}>

            </Route>

            {/* exhibitor and organiser menu code starts here */}
            <Route exact path="/adminpanel" render={() => {
              return (
                <>
                  {user.isLoggedIn !== "N" ? <Redirect to="/dashboard" /> :
                    <PanelLogin commonData={commonData} toast={toastmsg} />}
                </>
              )
            }}>
            </Route>
            <Route exact path="/dashboard" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/adminpanel" /> :
                    [user.user_type !== 1
                      ? <Dashboard commonData={commonData} />
                      : <Redirect to="/home" />
                    ]

                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/details" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/adminpanel" /> :
                    [user.user_type !== 1
                      ?
                      [user.user_type == 2
                        ? <OrgDetailView commonData={commonData} />
                        : <Redirect to="/dashboard" />
                      ]
                      : <Redirect to="/home" />
                    ]

                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/visitorlog" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/adminpanel" /> :
                    [user.user_type !== 1
                      ?
                      [user.user_type == 2
                        ? <VisitorLog commonData={commonData} />
                        : <Redirect to="/dashboard" />
                      ]
                      : <Redirect to="/home" />
                    ]

                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/auditorium_master" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/adminpanel" /> :
                    [user.user_type !== 1
                      ?
                      [user.user_type == 2
                        ? <AuditoriumMaster commonData={commonData} />
                        : <Redirect to="/dashboard" />
                      ]
                      : <Redirect to="/home" />
                    ]

                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/profile" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <Profile commonData={commonData} />
                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/briefcase" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" ? <Redirect to="/login" /> :
                    <Briefcase commonData={commonData} />
                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/stallmanagement" render={() => {
              return (
                <>
                  {user.isLoggedIn === "N" || user.user_type !== 0 ? <Redirect to="/adminpanel" /> :
                    <StallMangement commonData={commonData} />
                  }

                </>
              )
            }}>
            </Route>
            <Route exact path="/reset/:name" render={() => {
              return (
                <>
                  <ResetPassword commonData={commonData} toast={toastmsg} />
                </>
              )
            }}>
            </Route>
            <Route exact path="/hofficeuser/:name" render={() => {
              return (
                <>
                  <PaymentPage commonData={commonData} toast={toastmsg} />
                </>
              )
            }}>
            </Route>
            <Route exact path="*" render={() => {
              return (
                <>
                  <NotFound commonData={commonData} />

                </>
              )
            }}>
            </Route>

          </Switch>
          {user.isLoggedIn !== "N" && !window.location.pathname.includes("hofficeuser") ? <Footer commonData={commonData} /> : ""}

          <Payment commonData={commonData} />

          {user.isLoggedIn === "Y"
          ? <VideoChat commonData={commonData} />
          :""
          }
        </Suspense>

      </Router>


    </div>
  );
}

export default App;
